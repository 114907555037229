function map_recenter(latlng, offsetx, offsety, map) {
    if (map.getProjection() === undefined) {
        return setTimeout(function() {
            return map_recenter(latlng, offsetx, offsety, map);
        }, 150);
    }

    var point1 = map.getProjection().fromLatLngToPoint(
        (latlng instanceof google.maps.LatLng) ? latlng : map.getCenter()
    );
    var point2 = new google.maps.Point(
        ((typeof(offsetx) == 'number' ? offsetx : 0) / Math.pow(2, map.getZoom())) || 0,
        ((typeof(offsety) == 'number' ? offsety : 0) / Math.pow(2, map.getZoom())) || 0
    );
    map.setCenter(map.getProjection().fromPointToLatLng(new google.maps.Point(
        point1.x - point2.x,
        point1.y + point2.y
    )));
}

window.initGoogleMaps = function() {
    if (typeof google !== "object") {
        return setTimeout(initGoogleMaps, 300);
    }

    $('.google-map-canvas').each(function() {
        var mapElement = this;
        var id = $(this).data('map-id');
        var mapMarkers = new Array();

        var markers = JSON.parse($(this).data('markers').replace(new RegExp('\'', 'g'), '"').replace(new RegExp('\n', 'g'), ''));

        var map = new google.maps.Map($(this).get(0), {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 10,
            mapTypeId: google.maps.MapTypeId[$(this).data('type')],
            draggable: $(this).data('draggable'),
            scrollwheel: $(this).data('scrollwheel'),
            mapTypeControl: $(this).data('map-type-control'),
            zoomControl: $(this).data('zoom-control'),
            streetViewControl: $(this).data('street-view-control')
        });

        // Init Info-Slider
        mapElement.infoslider = $(this).parent('.map-wrapper').find('.contact-box-wrapper .contact-box > .informational-slider').slick({
            accessibility: false,
            autoplay: false,
            arrows: false,
            dots: false,
            draggable: false,
            fade: true, //# EXPERIMENTAL!
            focusOnSelect: false,
            infinite: false,
            mobileFirst: true, //# EXPERIMENTAL!
            respondTo: 'slider',
            slide: '.contact-information-slide',
            slidesToShow: 1,
            speed: 500,
            swipe: false,
            touchMove: false,
            waitForAnimate: false
        });

        $.each(markers.markers, function(index, marker) {
            geocoder = new google.maps.Geocoder();

            geocoder.geocode({
                'address': marker.street + ', ' + marker.zip + ', ' + marker.city + ', ' + marker.country
            }, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var marker = new google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location
                    });

                    mapMarkers.push(marker);

                    if (marker.title != '') {
                        google.maps.event.addListener(marker, 'click', function() {
                            mapElement.infoslider.slick('slickGoTo', index);
                        });
                    }
                }

                if ($('.google-map-canvas[data-map-id=' + id + ']').data('fit-bounds') == 1) {
                    var bounds = new google.maps.LatLngBounds();

                    for (var i = 0; i < mapMarkers.length; i++) {
                        bounds.extend(mapMarkers[i].getPosition());
                    }

                    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                        var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                        var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                        bounds.extend(extendPoint1);
                        bounds.extend(extendPoint2);
                    }

                    map.fitBounds(bounds);

                    // if ($(window).width() > 767) {
                    //   var width = $(mapElement).parents('.map-wrapper').find('.contact-box-wrapper').outerWidth();
                    //   map_recenter(map.getCenter(), width / 2, 0, map);
                    // }
                }

                $(window).on('resize', function() {
                    map.fitBounds(bounds);

                    if ($(window).width() > 767) {
                        var width = $(mapElement).parents('.map-wrapper').find('.contact-box-wrapper').outerWidth();
                        map_recenter(map.getCenter(), width / 2, 0, map);
                    }
                }).trigger('resize');
            });
        });

        if ($(this).data('fit-bounds') == 0) {
            map.setCenter({
                lat: $(this).data('lat'),
                lng: $(this).data('lng')
            });

            map.setZoom($(this).data('zoom'));

            var width = $(mapElement).parents('.map-wrapper').find('.contact-box-wrapper').outerWidth();
            map_recenter(map.getCenter(), width / 2, 0, map);
        }

        if (map.getTilt()) {
            map.setTilt($(this).data('tilt'));
        }

        $("div[id^='map-overlay-']").click(function() {
            $("div[id^='map-overlay-']").css("visibility", "hidden");
        });

        $(".google-map-canvas").mouseleave(function() {
            $("div[id^='map-overlay-']").css("visibility", "visible");
        });
    });
}

$(document).ready(initGoogleMaps);